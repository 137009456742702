<template>
  <div class="accountSetting">
    <el-button @click="addClick('添加子账号')" type="primary" size="mini"
      >添加子账号</el-button
    >
    <noData v-show="!tableData.length"></noData>
    <el-table v-show="!!tableData.length" :data="tableData" style="width: 100%">
      <el-table-column prop="name" label="子账号名称"> </el-table-column>
      <el-table-column prop="created_time" label="添加时间"> </el-table-column>
      <el-table-column prop="address" label="功能权限">
        <template #default="scope">
          <span
            @click="editDialog(scope.row,'编辑子账号')"
            :class="scope"
            style="color: #435B8E; cursor: pointer"
            >编辑</span
          ></template
        >
      </el-table-column>
      <el-table-column prop="address" label="是否生效">
        <template #default="scope">
          <el-switch
            @change="changeSwich($event,scope.row)"
            v-model="scope.row.state"
            active-color="#F04848"
            inactive-color="#D3D3D3"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <span class="delete-btn" @click="delRow(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <add-update
      @getList='getList'
      ref="addUpdate"
      :row="row"
      :nums="nums"
      :tableData="tableData"
      :title='title'
      v-model="dialogVisible"
    ></add-update>
  </div>
</template>

<script>
import { reactive, toRefs, ref, getCurrentInstance, defineComponent, onMounted, nextTick } from 'vue'
import addUpdate from './add-update'
import axios from '@/utils/request'
import { ElMessage } from 'element-plus'
import noData from '@/components/no-data'

export default defineComponent({
  components: {
    addUpdate,
    noData
  },
  setup () {
    const {
      proxy: { $confirm }
    } = getCurrentInstance()
    const addUpdate = ref(null)
    const state = reactive({
      tableData: [],
      dialogVisible: false,
      row: {}
    })
    const title = ref('add')
    // 编辑弹窗
    const editDialog = (row, val) => {
      title.value = val
      setTimeout(() => {
        state.row = row
      })
      addUpdate.value.getChildDetail(row)
      state.dialogVisible = true
    }
    const nums = ref(0)
    const delRow = async (row) => {
      $confirm('删除子账号后不可恢复，请确认是否继续？', '提示', {
        confirmButtonText: '确定',
        confirmButtonType: '',
        cancelButtonText: '取消'
      }).then(() => {
        axios.post('/api/v1/customer/delete', {
          id: row.id
        }, { loading: true }).then(res => {
          if (res.error_code === 0) {
            ElMessage.success('删除成功')
            getList()
          } else {
            ElMessage.error(res.error_msg)
          }
        })
      })
    }
    // 字符串截取方法
    const getCaption = (obj = '') => {
      var index = obj.lastIndexOf('-')
      obj = obj.substring(index + 1, obj.length)
      return obj
    }
    // 是否生效接口
    const changeSwich = (e, row) => {
      axios.post('/api/v1/customer/change_state', {
        id: row.id,
        state: e === true ? 'yes' : 'no'
      }).then(res => {
        if (res.error_code === 0) {
          ElMessage.success(res.data.message)
        } else {
          ElMessage.error(res.data.message)
        }
      })
    }
    const getList = async () => {
      const res = await axios.get('/api/v1/customer/get_child_customers', {
        headers: {
          Accept: 'application/json'
        },
        loading: true
      })
      state.tableData = res?.data ? res.data : []
      nums.value = Number(getCaption((state.tableData[state.tableData.length - 1] || {}).name)) + 1
      // if (res.error_code !== 0) {
      //   ElMessage.error(res.error_msg)
      // }
    }
    // 字符串截取方法 test08-1 ==>1

    // 点击添加子账号
    const addClick = (val) => {
      title.value = val
      nextTick(() => {
        const name = state.tableData[state.tableData.length - 1]
          ? state.tableData[state.tableData.length - 1].name : ''
        nums.value = Number(getCaption(name)) + Number(1)
      })
      // window.localStorage.setItem('nums', nums.value)
      state.dialogVisible = true
    }
    onMounted(() => {
      getList()
    })

    return {
      state,
      ...toRefs(state),
      editDialog,
      delRow,
      addClick,
      getList,
      getCaption,
      nums,
      title,
      addUpdate,
      changeSwich
    }
  }
})
</script>
<style scoped lang='scss'>
.accountSetting {
  padding: 21px 24px 24px;
  text-align: start;
  height: fit-content;
  background: #fff;
  max-height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  .delete-btn {
    color:#435B8E;
    cursor: pointer;
  }
  .el-button--primary {
    height: 36px;
    width: 102px;
    margin-top: 0;
    :deep()span {
      font-size: 14px !important;
    }
  }
}
.el-button {
  margin-top: 11px;
  margin-bottom: 24px;
}
.tableBtn {
  color: red;
}
</style>

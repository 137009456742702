<template>
  <div class="child" v-show="showType">
    <div v-show="showCard" class="card">
      <span>{{
        item.shop_name +
        "-" +
        item.first_industry_name +
        "-" +
        item.second_industry_name +
        "-" +
        item.third_industry_name +
        "(" +
        item.start_time +
        "-" +
        item.end_time +
        ")"
      }}</span>
    </div>
    <el-switch
      size="small"
      v-model="state.checked"
      active-color="#F04848"
      inactive-color="#D3D3D3"
    >
    </el-switch>
    <div v-show="!showCard || state.showMenu">
      <!-- 平台选择+类目+权限+起止日期 -->
      <div class="selPlatform">
        <span>平台选择：</span>
        <div class="radioBox">
          <el-radio-group @change="selPlatformFn" v-model="state.selPlatform">
            <el-radio
              v-for="(item, index) in sjqxList"
              :key="index"
              :label="item.id"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>
      <div class="selPlatform" v-show="state.showOne">
        <span>一级类目：</span>
        <div class="radioBox">
          <el-radio-group @change="selOneType" v-model="state.oneType">
            <el-radio
              v-for="(item, index) in state.oneLeimu"
              :key="index"
              :label="item.id"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>
      <div class="selPlatform" v-show="state.showTwo">
        <span>二级类目：</span>
        <div class="radioBox">
          <el-radio-group @change="selTwoType" v-model="state.twoType">
            <el-radio
              v-for="(item, index) in state.twoLeimu"
              :key="index"
              :label="item.id"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>
      <div class="selPlatform" v-show="state.showThree">
        <span>三级类目：</span>
        <div class="radioBox">
          <el-radio-group v-model="state.threeType">
            <el-radio
              v-for="(item, index) in state.threeLeimu"
              :key="index"
              :label="item.id"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>
      <div class="selPlatform">
        <span>数据下载权限：</span>
        <el-switch
          v-model="state.downLoad"
          active-color="#F04848"
          inactive-color="#D3D3D3"
        >
        </el-switch>
      </div>
      <el-row>
        <el-col :offset="8">
          <span>起止时间：</span>
          <el-date-picker
            size="mini"
            v-model="state.start_time"
            type="date"
            placeholder="选择开始日期"
          >
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row>
        <el-col :offset="8">
          <span>结束时间：</span>
          <el-date-picker
            size="mini"
            v-model="state.end_time"
            type="date"
            placeholder="选择结束日期"
          >
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row>
        <el-col :offset="18">
          <el-button size="mini" @click="addLabel">添加</el-button>
          <el-button size="mini">取消</el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// import { confirm } from 'element-plus'
import { reactive, getCurrentInstance, onMounted, watch } from 'vue'
import axios from '@/utils/request'
// import { useRouter } from 'vue-router'
export default {
  props: [
    'showType',
    'showCard',
    'item',
    'openCheckAll',
    'sjqxList',
    'cardList',
    'switchList',
    'sendChild'
  ],
  setup (props, ctx) {
    const {
      ctx: { $message, $confirm }
    } = getCurrentInstance()
    const state = reactive({
      // sendChild: [],
      checked: false,
      downLoad: false,
      label: '',
      labelCheckBox: ['京东-6.18-美团'],
      showOne: false,
      showTwo: false,
      showThree: false,
      oneType: '',
      twoType: '',
      threeType: '',
      hangyepaihang: true,
      dianpupaihang: false,
      pinpaiPaihang: true,
      shangpinpaihang: false,
      pinpaixiangqing: false,
      permission_industry: {},
      start_time: '',
      end_time: '',
      showMenu: false,
      oneLeimu: [],
      twoLeimu: [],
      threeLeimu: [],
      oneTypes: '',
      twoTypes: ''
    })
    const selPlatformFn = (val) => {
      state.platform = val
      state.oneType = val
      state.showOne = true
      axios
        .get('/api/v1/basedata/get_categories/', {
          params: {
            website_id: val
          }
        })
        .then((res) => {
          // 一级id
          state.oneLeimu = res.data
        })
    }
    // 方法
    watch(() => state.checked, () => {
      ctx.emit(state.checked ? 'selChildCard' : 'delChildCard', props.item)
    })
    const openAll = () => {
      state.checked = true
    }
    const cardClick = () => {
      state.showMenu = !state.showMenu
      // 点击展开后---显示所有信息---也就是props.item
      const itemData = props.item
      // state.selPlatform = itemData.
      // 触发平台选择的方法，自动选择平台
      ctx.emit('selHangye')
      state.selPlatform = itemData.shop_id
      // selplatformFn()
      state.showOne = true
      selPlatformFn(itemData.shop_id)
      state.showTwo = true
      state.oneType = itemData.first_industry
      selOneType(itemData.first_industry)
      state.showThree = true
      state.twoType = itemData.second_industry
      selTwoType(itemData.second_industry)
      state.threeType = itemData.third_industry
      state.downLoad =
        itemData.download_pms === 1 ? true : itemData.download_pms !== 0
      state.start_time = itemData.start_time
      state.end_time = itemData.end_time
    }
    // 删除当前标签！！！！清空对象内容
    const deleteCard = () => {
      // state.permission_industry = {}
      // state.label = ''
      $confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ctx.emit('delChild')
        $message({
          type: 'success',
          message: '删除成功!'
        })
      })
    }
    // 封装好后台所需要的参数！~！！！点击添加的时候生成标签传给父组件--以供提交给后台
    const addLabel = () => {
      state.permission_industry.id = props.item.id
      // 如果是编辑，传id-----------------------------------如上------
      state.permission_industry.localId = +(Math.random() * 10000).toFixed(0)
      state.permission_industry.shop_id = state.selPlatform
      state.permission_industry.first_industry = state.oneType
      state.permission_industry.second_industry = state.twoType
      state.permission_industry.third_industry = state.threeType
      state.permission_industry.download_pms =
        state.downLoad === true ? '1' : '0'
      state.permission_industry.start_time = state.start_time.valueOf()
      state.permission_industry.end_time = state.end_time.valueOf()

      state.label = `${state.selPlatform}-${state.oneType}-${state.twoType}-${
        state.threeType
      }-${state.hangyepaihang}-${state.pinpaipaihang}-${
        state.pinpaixiangqing
      }-${state.dianpupaihang}-${
        state.shangpinpaihang
      }-${state.start_time.valueOf()}-${state.end_time.valueOf()}`
      if (state.label) {
        state.showMenu = false
        // 传值给父组件控制按钮显示隐藏
        ctx.emit('addShow', !state.showMenu)
        ctx.emit('itemCon', state.permission_industry)
      } else {
        state.showMenu = true
        ctx.emit('addShow', !state.showMenu)
      }
    }
    // 监听全部开启
    watch(
      () => props.openCheckAll,
      (newVal, oldVal) => {
        if (newVal === true) {
          state.checked = true
        } else {
          state.checked = false
        }
      }
    )
    onMounted(() => {
      // props.item -----props.sendChild
      if (props.sendChild && props.item) {
        props.sendChild.map((itemss) => {
          if (itemss.parent_id === props.item.id) {
            state.checked = true
            if (state.checked === true) {
              // 选中状态
              ctx.emit('selChildCard', props.item)
            }
          }
        })
      }
      if (props.openCheckAll === true) {
        state.checked = true
      }
    })
    // 平台选择
    const selOneType = (val) => {
      state.oneTypes = val
      state.showTwo = true
      axios
        .get('/api/v1/basedata/get_categories/', {
          headers: {
            Accept: 'application/json'
          },
          params: {
            website_id: state.platform,
            first_id: val
          }
        })
        .then((res) => {
          // 一级id
          state.twoLeimu = res.data
        })
    }
    const selTwoType = (val) => {
      state.showThree = true
      axios
        .get('/api/v1/basedata/get_categories/', {
          headers: {
            Accept: 'application/json'
          },
          params: {
            website_id: state.platform,
            first_id: state.oneTypes,
            second_id: val
          }
        })
        .then((res) => {
          // 一级id
          state.threeLeimu = res.data
        })
    }
    return {
      state,
      selPlatformFn,
      selOneType,
      selTwoType,
      addLabel,
      deleteCard,
      cardClick,
      // changeCheck,
      openAll
    }
  }
}
</script>
<style scoped lang='scss'>
.child {
  // border: 1px solid red;
  background: #f7f8fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
  .card {
    padding-left: 12px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    line-height: 42px;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      font-size: 14px;
      color: #242426;
      text-overflow: ellipsis;
      display: inline-block;
    }
    .el-button {
      margin-left: 20px;
    }
  }
  .selPlatform {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 332px;
    margin-bottom: 20px;
    .el-radio {
      width: 140px;
    }
    .el-checkbox {
      width: 120px;
    }
    span {
      white-space: nowrap;
    }
    .el-checkbox-group {
      display: flex;
      flex-wrap: wrap;
      .el-checkbox {
        margin-bottom: 6px;
      }
    }
    .radioBox {
      display: flex;
      flex-wrap: wrap;
    }
    .el-radio-group {
      display: flex;
      flex-wrap: wrap;
      margin-top: 3px;
      .el-radio {
        margin-bottom: 6px;
      }
    }
  }
}
</style>
